.secondary-button, .primary-button, .dark-button, .white-button {
    font-family: var(--px-primary-font);
    font-weight: 400;
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
    color: var(--px-white);
    border: 1px solid var(--px-white);
    border-radius: 2rem;
    transition: background-color 0.5s ease;    
}
.dark-button {
    font-size: 1rem;
}
.primary-button {
    background-color: var(--px-blue-light);
}
.dark-button {
    background-color: var(--px-blue-dark);
    padding-right: 2rem;
    padding-left: 2rem;
    margin: 0 0.25rem;
}
.dark-button.grey {
    background-color: grey;
    padding-right: 2rem;
    padding-left: 2rem;
    margin: 0 0.25rem;
    cursor: not-allowed;

}
.coming-soon {
    display: none;
    position: absolute;
    background-color: none;
    color: #000000;
    font-size: 0.85rem;
    font-weight: 500;
    // border-radius: 2rem;
    text-align: center;
    // line-height: 2.5rem;
    padding: 0.25rem 0.5rem;
    cursor: not-allowed;
    z-index: 1000;
    pointer-events: auto;

  }
  
  
  .dark-button.grey:hover .coming-soon {
    display: block;
  }
  

.secondary-button:hover {
    background-color: var(--px-blue-light);
}

.purple-button {
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    background-color: var(--px-purple);
    color: var(--px-white);
    cursor: pointer;
}
.white-button {
    color: black;
    background-color: var(--px-white);
    h5 {
        font-weight: 700;
        font-size: 1rem;
    }
}
.back-button {
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    cursor: pointer;

    .button-text {
        text-decoration: underline;
    }
    img {
        padding-right: 0.25rem;
    }
}


.map-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    cursor: pointer;

    .button-text {
        color: var(--px-white);  
        text-decoration: underline;
    }
    img {
        padding-right: 0.5rem;
    }
}