.qr-scanner-dialog-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  height: 100vh;
  width: 100%;

  .qr-scanner-dialog {
    background: var(--px-white);
    padding: 20px;
    border-radius: 20px 20px 0 0;
    width: 100%;
    // max-width: 500px;
    position: relative;
    animation: slideUp 0.3s ease-out;
    max-height: 90vh;
    min-height: 500px;
    overflow-y: auto;

    .scanner-view {
      width: 100%;

      .scan-heading {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
      }

      .qr-reader-container {
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }

    .result-view {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .result-icon {
        width: 5rem;
      }

      .result-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .result-heading {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
      }

      .result-content {
        background: #f5f5f5;
        padding: 20px;
        border-radius: 8px;
        margin: 20px 0;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }

    .scan-again-button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 12px 24px;
      border-radius: 6px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }

    .error-message {
      color: #dc3545;
      margin: 1rem 0;
      text-align: center;
    }

    .loading-spinner {
      margin: 20px 0;
      font-size: 18px;
      color: #666;
    }

    .close-button {
      position: absolute;
      right: 20px;
      top: 20px;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      z-index: 1;
      color: #666;

      &:hover {
        color: #333;
      }
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
