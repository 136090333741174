.event-details-main-container {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.starting-price-banner-mobile {
  display: none;
  width: 90%;
  margin-top: 1rem;
  // background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 0px 0px var(--px-purple);
  -moz-box-shadow: 10px 10px 0px 0px var(--px-purple);
  box-shadow: 10px 10px 0px 0px var(--px-purple);
  padding: 2rem 4rem;
  border: 1px solid var(--px-purple);
  margin-bottom: 1rem;

  .price {
    text-align: center;
    font-size: 1.25rem;
    color: black;
  }
  .banner-heading {
    font-size: 1rem;
    // margin-bottom: 10px;
    text-align: center;
    color: var(--px-gray);
  }

  @include down-md {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.event-description {
  // @include down-md {
  //     margin: 0;
  // }
  display: block;
  .heading {
    font-size: 1.5rem;
    font-weight: 500;
    //   margin-bottom: 10px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .subheading {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  padding-left: 2rem;
  @include down-md {
    margin-top: 2rem;
    padding: 0;
  }
}
.event-details-main {
  width: 100%;
  font-family: var(--px-primary-font);
  color: black;
  max-width: 1400px;
  // height: 80vh;
  // max-height: 500px;
  min-height: 400px;
  // background-color: var(--px-white);
  display: flex;
  padding: 0rem 2rem;
  justify-content: space-between;
  //   margin: 1rem 2rem;

  @include down-md {
    flex-direction: column;
    padding: 0;
  }
  .left-side {
    // flex: 1.5;
    padding-right: 20px;
    width: 60% !important;

    .basic-information {
      margin-bottom: 2rem;
      .heading-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
    }
    .heading {
      font-size: 1.5rem;
      font-weight: 500;
      //   margin-bottom: 10px;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .subheading {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    .location-section {
      color: black;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;
      img {
        margin-right: 0.5rem;
      }
    }

    .event-information {
      .info-container {
        display: flex;
        flex-direction: row;
        @include down-xsm {
          flex-direction: column;
        }
      }
      .container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-right: 2rem;
        .content {
          font-size: 1.25rem;
          display: flex;
          flex-direction: column;
          // align-items: center;
          text-align: left;
          margin-left: 0.5rem;
        }
        .description {
          font-size: 1rem;
          color: var(--px-gray);
        }
        img {
          margin-right: 0.5rem;
          width: 2.5rem;
        }
        @include down-xsm {
          margin-bottom: 1rem;
        }
      }
    }
    @include down-md {
      width: 100% !important;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    // flex: 1;
    color: black;
    width: 40% !important;

    .starting-price-banner {
      width: 60%;
      margin-right: 0.5rem;
      background-color: #fff;
      border-radius: 8px;
      -webkit-box-shadow: 10px 10px 0px 0px var(--px-purple);
      -moz-box-shadow: 10px 10px 0px 0px var(--px-purple);
      box-shadow: 10px 10px 0px 0px var(--px-purple);
      padding: 3rem 2rem;
      border: 1px solid var(--px-purple);

      .heading {
        font-size: 1rem;
        // margin-bottom: 10px;
        text-align: center;
        color: var(--px-gray);
      }

      .price {
        text-align: center;
        font-size: 1.25rem;
        color: black;
      }
      @include down-md {
        display: none;
      }
    }
    @include down-md {
      margin-left: 0;
      width: 100%;
    }
  }
}
.tags-container {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-bottom: 1rem;
  button {
    margin: 0.5em;
    padding: 0.5rem;
    background-color: #f8f8fa;
    border-radius: 0.35rem;
  }
  .tag-wrapper {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
.tags {
  padding-left: 2rem;
  font-family: "Inconsolata";
  .heading {
    font-size: 1.5rem;
    font-weight: 700;
    //   margin-bottom: 10px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  @include down-md {
    padding: 0;
  }
}
.contact-share {
  width: 100%;
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .heading {
    font-family: "Inconsolata";
    font-size: 1.5rem;
    font-weight: 700;
    //   margin-bottom: 10px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  a {
    color: var(--px-purple);
  }
  .right-side {
    margin-left: 3rem;
    flex-wrap: wrap;
    .heading {
      margin-bottom: 1.25rem;
    }
    .social-icons {
      // display: flex;
      // flex-direction: row;
      flex-wrap: wrap;
      margin-right: 1rem;
      img {
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }
  @include down-md {
    padding: 0;
  }
}

.payment-button-container {
  display: flex;
  justify-content: right;
  align-items: center;
  .payment-button {
    background-color: var(--px-purple);
    color: var(--px-white);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
  }
}
