.search-term {
  font-size: 1.5rem;
  font-weight: 500;
  padding-left: 2rem;
  max-width: 1400px;
  margin: auto;
  @include down-xsm {
    font-size: 1rem;
  }
}
.item-inactive {
  color: grey;
}

.main-body {
  font-family: var(--px-primary-font);
  padding: 2rem;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  display: flex;

  .content {
    width: 80%;
    @include down-md {
      width: 100%;
      padding-left: 0;
      padding-top: 2rem;
    }
    .not-found {
      display: block;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      margin-top: 20%;
    }
  }

  .event-list-container {
    padding-bottom: 20px;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    height: min-content;
    @include down-md {
      padding-left: 0;
      padding-bottom: 2rem;
    }

    .category-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 2rem;
      font-weight: 700;
      max-width: 1400px;
      margin: auto;
      width: 100%;
      text-align: left;
      @include down-xsm {
        font-size: 1.5rem;
      }

      .see-all-button {
        color: var(--px-purple);
        font-size: 1rem;
      } 
    }
  }

  .clear-button {
    color: var(--px-purple);
    text-decoration: underline;
  }
  .sidebar {
    font-size: 1rem;
    width: 20%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 1rem;
    // margin-right: 2rem;
    .section-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .section-content {
      margin-bottom: 30px;
    }
    .heading {
      font-weight: 500;
      padding: 0.5rem;
    }

    .item {
      display: flex;
      align-items: center;
      padding-left: 2.5rem;
      color: rgb(0, 0, 0);

      text-transform: capitalize;
      h6 {
        padding-left: 5px;
      }
    }

    .item-active {
      color: var(--px-purple);
      font-weight: 500;
    }
    .item-grey {
      color: grey;
    }
    hr {
      margin: 0.5rem 0.5rem;
      border: none;
      border-top: 1px solid #ccc;
    }

    .flex {
      display: flex;
    }

    .center {
      justify-content: center;
    }

    .justify-between {
      justify-content: space-between;
    }

    .slider {
      margin: 0 0.5rem;
      width: 95%;
    }

    .price-input {
      width: 100%;
      // overflow: hidden;
      div {
        width: 40%;
      }
    }

    input[type="number"] {
      width: 100%;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid gray;
    }

    .switches {
      display: flex;
      flex-direction: row;
    }
    @include down-md {
      width: 100%;
    }
  }
  .event-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    // height: min-content;
  }
  @include down-md {
    flex-direction: column;
  }
}