.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: space-between; 
    padding-right: 20px; /* Add some padding to ensure text doesn't touch the edge */
    width: auto;
}






