@import "mixin";

.header-top-fixed {
  display: block;
  width: 100%;
  background: var(--px-blue-dark);
  z-index: 5;
  padding: 0;

  // .gradient {
  //   display: none;
  // }

  // .gradient.show {
  //   position: absolute;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 900;
  // }

  .navbar {
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 1400px;
    @include down-sm {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .menu-container {
    display: flex;
    z-index: 6;
    @include down-sm {
      display: block;
    }
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    h6 {
      font-size: 2rem;
      font-weight: 800;
      font-family: $px-primary-font;
      color: var(--px-white);
      padding-left: 0.5rem;
    }
    img {
      height: 2rem;
    }

    // @include down-xsm {
    //   display: block;
    //   width: 100%;
    //   text-align: center;
    // }
  }
  .profile-button-wrapper {
    cursor: pointer;
    .profile {
      display: flex;
      flex-direction: row;
      li {
        padding-left: 20px;
      }

      .profile-details {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: $px-primary-font;
        .name {
          color: var(--px-white);
          font-weight: 500;
          font-size: 1rem;
          line-height: 1rem;
        }
        .username {
          color: var(--px-gray);
          font-size: 0.75rem;
          line-height: 0.75rem;
        }
        img {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 10px;
        }
      }

      // li a {
      //   margin-left: 20px;
      // }
      @include down-md {
        display: none;
      }
    }
  }
  .vertical-line {
    border: 1px solid var(--px-white);
    margin-top: 2px;
    margin-bottom: 2px;
    opacity: 0.2;
  }

  .main-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    li {
      z-index: 5;
    }
    li a {
      margin-right: 20px;
    }
    @include down-md {
      display: none;
    }
    .disabled-option {
      cursor: default;
    }
    .tooltip {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      pointer-events: none;
      z-index: 5;
    }
  }
  /* Hide mobile-auth-option on larger screens */
  .mobile-auth-option {
    display: none;
  }

  /* Show mobile-auth-option only on smaller screens (max-width: 768px) */
  @media (max-width: 768px) {
    .mobile-auth-option {
      display: block;
      padding: 10px 0; /* Adjust padding as needed */
      text-align: center; /* Center-align text */
    }
  }

  .hamburger-menu {
    display: none;
    @include down-md {
      display: block;
      background: none;
      border: none;
      color: var(--px-white);
      font-size: 2rem;
      cursor: pointer;
    }
  }

  .main-menu {
    color: var(--px-white);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;

    li a {
      position: relative;
      cursor: pointer;
      transition: all 0.4s ease;
      text-transform: capitalize;

      &:hover {
        color: var(--px-white);
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 1px;
        bottom: 0;
      }

      &:after {
        right: 2.5px;
        background: var(--px-blue-light);
        transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:before {
        background: var(--px-blue-light);
        width: 100%;
        transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:after {
        background: transparent;
        width: 100%;
        transition: 0s;
      }
    }
  }

  .profile-menu {
    display: none;
    color: var(--px-white);
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;

    .horizontal-line {
      padding: 0 0.5rem 1rem 0.5rem;
      opacity: 0.5;
    }

    li a,
    li button {
      // position: relative;
      // cursor: pointer;
      // transition: all 0.4s ease;
      // text-transform: capitalize;

      .profile-menu-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
        div {
          display: flex;
          flex-direction: row;
          gap: 5px;
        }

        .profile-details {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: $px-primary-font;
          .name {
            color: var(--px-white);
            font-weight: 500;
            font-size: 1rem;
            line-height: 1rem;
          }
          .username {
            color: var(--px-gray);
            font-size: 0.75rem;
            line-height: 0.75rem;
          }
          img {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 10px;
          }
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }

      &:hover {
        color: var(--px-white);
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 1px;
        bottom: 0;
      }
    }
  }
}

.main-menu.show {
  display: block;
  position: fixed;
  top: 10%;
  right: 0;
  background: var(--px-blue-dark);
  // width: 50%;
  padding: 1rem;
  z-index: 5;
  border-radius: 10px;
  // transform: translate(-50%, -50%);
  box-sizing: border-box; /* Ensure padding is included in the width */

  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);

  li {
    padding: 0.5rem 0 0.5rem 1rem;
    text-align: center;
  }
}

.profile-menu.show {
  display: block;
  position: fixed;
  top: 75px;
  right: 5%;
  background: var(--px-blue-dark);
  // width: 50%;
  padding: 1rem 1rem 0 1rem;
  z-index: 5;
  border-radius: 10px;
  // transform: translate(-50%, -50%);
  box-sizing: border-box; /* Ensure padding is included in the width */

  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);

  @media (min-width: 1501px) {
    right: calc((100vw - 1500px) / 2);
  }

  // li {
  //   padding: 0.5rem 0 0.5rem 1rem;
  //   text-align: center;
  // }
}

.header-scroll {
  opacity: 0.5;
}

// search form
.search-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.5rem;
  max-width: 1400px;

  .search-form, .search-form-mobile {
    padding: 1.5rem;
    border-radius: 1rem;
    font-family: var(--px-primary-font);
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--px-white);
    gap: 10px; /* Adds space between input fields */

    .advanced-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      height: 0;
      opacity: 0;
      transition: height 1s ease, opacity 1s ease;
      width: 70%;

      @include down-sm {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &.show {
        height: auto;
        opacity: 1;
        transition: height 1s ease, opacity 1s ease;
      }
    }

    .general-input-container {
      width: 30%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .expand-icon {
        padding-left: 5px;
        transition: transform 0.3s ease;
        &.rotate-icon {
          transform: rotate(-180deg);
        }
      }
      @include down-sm {
        width: 100%;
      }
    }

    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: var(--px-white);
      border-radius: 2rem;
      padding: 0.25rem 0.5rem;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      flex: 1;
      width: 30%;
      z-index: 5;

      .dropdown-empty {
        color: var(--px-gray);
        option {
          color: black;
        }
      }
    }

    .input-container img {
      padding-right: 0.25rem;
    }

    .input-container input {
      font-size: 0.85rem;
      width: 80%;
      border: none;
      &:focus {
        outline: none;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .input-container select {
      border: none;
      width: 100%;
      font-size: 0.85rem;
      &:focus {
        outline: none;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .search-button {
      font-size: 0.85rem;
      background-color: var(--px-blue-light);
      padding: 1rem 1.5rem;
      border-radius: 2rem;
      color: var(--px-white);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    }

    .date-picker {
      width: 50%;
      z-index: 5;
    }

    .date {
      padding-left: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include down-sm {
      /* Adjust for mobile devices */
      display: flex;
      flex-direction: column;
      // gap: 0rem; /* Space between input fields on mobile */

      .input-container {
        width: 100%;
        // margin-bottom: 1rem; /* Space between input fields on mobile */
        // flex: none; /* Remove flex-grow/shrink behavior on mobile */
      }

      .search-button {
        width: 100%;
      }

      .date {
        padding-left: 0;
      }
    }
  }

  .search-form {
    display: flex;
  }

  @include down-sm {
    .search-form-mobile {
      display: flex;
    }
    .search-form {
      display: none;
    }
  }
}

.no-scroll {
  overflow: hidden;
}
