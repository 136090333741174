.map-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  margin-top: 2rem;

  @include down-md {
    margin-left: 0;
  }
}

.map-preview-pc {
  @include down-md {
    display: none;
  }
}

.map-image {
  border-radius: 10px;
  cursor: pointer;
  height: auto;
  @include down-md {
    width: 100vw;
  }
  // @include down-sm {
  //   height: 0;
  // }
}

.map-frame {
  width: 100%;
  // height: 300px;
  border: none;
  border-radius: 8px;
  // @include down-md {
  //   height: 0;
  // }
}

.description-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.description {
  flex: 1;
  margin-right: 20px;
}

.open-map-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.map-preview-mobile {
  display: none;
  @include down-md {
    display: flex;
  }
}
