.trending-event-list-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
  margin-top: 4rem;
  .heading-container {
    padding: 0 2rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    .heading {
      font-family: var(--px-primary-font);
      font-weight: 700;
      font-size: 1.5rem;
      @include down-xsm {
        font-size: 1.25rem;
      }
    }
  }
  .button-wrapper {
    padding-top: 1rem;
    display: inline-flex;
    // align-items: center;
    //justify-content: center;
  }
    .scroll-left,
    .scroll-right {
        padding: 0 1rem;
      @include down-xsm {
        display: none;
      }
    }
  
  .events-scroll-container-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .events-scroll-container {
    width: 100%;
    padding-bottom: 4rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer/Edge */
    .trending-events-wrapper {
      width: 100%;
      // height: 100%;
      // max-height: 300px;
      display:flex;
      gap: 15px;
      padding-left: 10px;
      @include down-xsm {
        padding: 0 20px;
      }
    }
  }
  .events-scroll-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers (Chrome/Safari) */
  }

  @include down-xsm {
    padding: 0;
  }
}
