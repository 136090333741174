.create-event-banner-container {
    background-color: var(--px-blue-medium);
    height: 400px;
    margin-top: 80px;
    padding-right: 2rem;
    padding-left: 2rem;
    font-family: var(--px-primary-font);
    color: var(--px-white);
    .items-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 1400px;
        margin-right: auto;
        margin-left: auto;
        height: 100%;
    }
    .image-container {
        width: 70%;
        position: relative; /* Allow image to overflow from top */
        top: 1.5rem;
        @include  down-xsm {
          display: none;
        }
      }
      
      .image {
        position: absolute;
        bottom: -400px;
        display: block;
        height: 450px;
        max-height: 500px;
        width: auto;
      }
      
      .content-container {
        width: 60%;
        padding: 20px;
        box-sizing: border-box;
        margin-top:auto;
        margin-bottom: auto;
        @include down-xsm {
          width: 100%;

        }
      }
      
      .heading {
        font-size: 2rem;
        margin-bottom: 0.2rem;
        font-weight: 700;
      }
      
      .subheading-container {
        margin-bottom: 20px;

        h4, ol {
          color: white;
          font-size: 1rem;
          font-weight: 400;
        }
      }
  }

  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .image-container,
    .content-container {
      width: 100%;
    }
  
    .image {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  