.city-card-container {
  font-family: var(--px-primary-font);
  width: 25vw;
  border-radius: 0.7rem;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s;
  margin-right: 1rem;
  box-shadow: 0px -0.27px 2.75px 0px rgba(0, 0, 0, 0.0217), 
              0px -0.69px 6.95px 0px rgba(0, 0, 0, 0.0311), 
              0px -1.42px 14.18px 0px rgba(0, 0, 0, 0.0389), 
              0px -2.92px 29.2px 0px rgba(0, 0, 0, 0.0483), 
              0px -8px 80px 0px rgba(0, 0, 0, 0.07);

  &:hover {
    box-shadow: 0px -0.27px 2.75px 0px rgba(0, 0, 0, 0.2217), 
                0px -0.69px 6.95px 0px rgba(0, 0, 0, 0.2311), 
                0px -1.42px 14.18px 0px rgba(0, 0, 0, 0.2389), 
                0px -2.92px 29.2px 0px rgba(0, 0, 0, 0.2483), 
                0px -8px 80px 0px rgba(0, 0, 0, 0.27);
  }

  .image-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .city-card-image {
    width: 100%;
    object-fit: cover;
    border-radius: 0.7rem;
  }

  .explore-button {
    position: relative;
    font-size: 0.8rem;
    font-weight: 500;
    bottom: 20px;
    left: 0;
    padding: 0.5rem 1.5rem;
    background-color: black;
    color: var(--px-white);
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  .explore-button-grey {
    position: relative;
    font-size: 0.8rem;
    font-weight: 500;
    bottom: 20px;
    left: 0;
    padding: 0.5rem 1.5rem;
    background-color: grey;
    color: var(--px-white);
    border: none;
    border-radius: 1rem;
    cursor: not-allowed;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
  }

  .city-card-content {
    padding: 0 16px;
  }

  .city-card-heading {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .subhead-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .city-card-subheading {
      font-size: 0.75rem;
      font-weight: 500;
    }

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  @include down-lg {
    width: 30vw;
  }

  @include down-md {
    width: 50vw;
  }

  @include down-xsm {
    width: 80vw;
  }
}

.city-card-container-grey {
  pointer-events: none;
  cursor: not-allowed;
}
