@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

/* Style for the copyright notice */
.copyright-2021-2022 {
  margin: 0;
  width: 9.313rem;
  height: 2.75rem;
  position: relative;
  font-weight: 800;
  font-family: 'Poppins', sans-serif; /* Updated for better fallback */
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
  color: #F2F6F9;
  text-align: center;
  font-style: normal;
}

/* Style for the background */
.bg {
  background-color: #0B0B0B; /* Solid background color */
  padding: 0 20px; /* Adds padding to prevent content touching the screen edges */
  height: 100vh;
  width: 100%;
  background-size: cover; /* This will make sure your image covers the entire element */
  background-position: center;
  overflow: hidden; 
  
}

/* Base styles for logo container */
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 12%; /* Adjusted for flexibility */
}

/* Style for the signup logo */
.signup-logo {
  width: 70px;
  height: 90px;
  flex-shrink: 0;
}

/* Style for the heading under the logo */
.logo-container h3 {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem; /* Adjust as needed */
}

.signup-background {
  display: flex;
  justify-content: space-between; /* Adjust based on your layout */
  align-items: flex-start;
  position: relative;
  height: 100vh;
  padding: 20px;
}

.create-new-account {
  position: relative;
  flex-basis: 50%; /* Takes up half of the container's width */
  margin: 0;
  height: auto; /* Adjust based on content, was 4.813rem */
  position: relative;
  font-size: 1rem; /* Adjust as needed, previously 'inherit' */
  font-weight: 600;
  font-family: 'Inter', sans-serif; /* Specify or inherit */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  z-index: 2;
  
}

.headingStyle {
  color: var(--Neutral-10, #FFF);
  font-family: 'Inter', sans-serif;
  font-size: 3.125rem; /* Converted from 50px */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 10rem; /* Converted from 160px */
  margin-left: 46.875rem; /* Converted from 750px */
  align-self: center; /* Center the heading within the container */
  width: auto; /* Let the heading size based on its content */
  grid-column: 1 / -1; /* Ensure the heading spans the full grid width */
  text-align: left; /* Align the heading text to the left */
}

.input-wrapper {
  border-radius: 8px;
  font-family: Inter;
  border: 1px solid var(--Neutral-90,#9E9E9E);
  display: flex;
  width: 200px; /* Fixed width */
  padding: 16px;
  align-items: center;
  /* position: fixed; */
  gap: 10px;
  margin-top: 240px; /* Moves the box down from its previous position */
  margin-left: 480px; /* Moves the box to the right */
  position: absolute;

}

.input-wrapper-1 {
  border-radius: 8px;
  font-family: Inter;
  border: 1px solid var(--Neutral-90,#9E9E9E);
  display: flex;
  width: 200px; /* Fixed width */
  padding: 16px;
  align-items: center;
  /* position: fixed; */
  gap: 10px;
  margin-top: 240px; /* Moves the box down from its previous position */
  margin-left: 980px; /* Moves the box to the right */
  position: absolute;

}

.input-wrapper-2 {
  border-radius: 8px;
  font-family: Inter;
  border: 1px solid var(--Neutral-90,#9E9E9E);
  display: flex;
  width: 450px; /* Fixed width */
  padding: 16px;
  align-items: center;
  /* position: fixed; */
  gap: 10px;
  margin-top: 300px; /* Moves the box down from its previous position */
  margin-left: 730px; /* Moves the box to the right */
  position: absolute;

}

.input-wrapper-3 {
  border-radius: 7px;
  font-family: Inter;
  border: 1px solid var(--Neutral-90,#9E9E9E);
  display: flex;
  width: 450px; /* Fixed width */
  height: 22px;
  padding: 16px;
  align-items: center;
  /* position: fixed; */
  gap: 10px;
  margin-top: 360px; /* Moves the box down from its previous position */
  margin-left: 730px; /* Moves the box to the right */
  position: absolute;

}


.first-name-input {
  flex-grow: 1; /* Allows the input to fill the space within the wrapper */
  background: transparent;
  border: none;
  outline: none;
  color: inherit; /* Inherits the text color from the parent */
  font-family: Inter;; /* Ensures the font matches your design */
  color:#FFF;

}



/* Already a member css */
.already-member {
  color: var(--Neutral-40, #E0E0E0); /* Fallback color is #E0E0E0 if --Neutral-40 is not defined */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center; /* Adjust based on your design */
  margin-top: 315px; /* Space above the text, adjust as needed */
  margin-left: 740px;
}

.login-link {
  color: #007BFF; /* Blue color for the link */
  text-decoration: none; /* Removes underline from the link */
}

.login-link:hover {
  text-decoration: underline; /* Adds underline on hover for better user interaction feedback */
}


/* For small screens */
@media (max-width: 600px) {
  .bg{
    background-size: cover;
    min-height: 100vh; 
    padding: 0;
    margin: 0;
  }
  .logo-container {
    top: 0%;
    right: 50%;
    transform: translateX(-50%); /* Correct adjustment for centering */
    width: auto; /* Adjust width as necessary */
    align-items: center;
  }

  .signup-logo, .logo-container h3 {
    width: 50px; /* Adjusted width for smaller screens */
  }

  .logo-container h3 {
    font-size: 1rem; /* Adjusted font size for smaller screens */
    margin-top: 0rem; /* Reduce space above the text if necessary */
    margin-bottom: 0.5rem; /* Reduce the bottom margin if necessary */
    text-align: center; /* This ensures text alignment is centered */
    width: 90%; /* This makes the text container's width equal to its parent's width */
  }
  .logo-container {
    position: relative; /* Adjusted if needed */
    text-align: center;
    right: 0; /* Resets any right positioning */
    top: 0; /* Keep at the top or adjust as needed */
    transform: none; /* Remove any transformations */
    width: auto; /* Adjust width as necessary */
  }
  .signup-background {
    flex-direction: column;
    align-items: center; /* Centers children horizontally */
    justify-content: start; /* Aligns children to the top */
  }

  .create-new-account {
    width: 100%; /* Adjust width to not be full but close for smaller screens */
    flex-basis: auto;
    align-items: center; /* Ensure content is centered */
  }

  .headingStyle {
    margin-top: 0rem; /* Reduced from previously larger value to decrease gap */
    font-size: 1.7rem; /* Adjusted font size for readability */
    margin-left: 0; /* Ensures no horizontal displacement */
    text-align: center;
  }

  .input-wrapper {
    position: absolute; /* Keep if you need the input to be absolutely positioned */
    width: 80%; /* Adjust the width to fit smaller screens better */
    top: 20px; /* Adjust the top position to not overlap with other elements */
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Correct horizontal centering */
    margin-top: 2rem; /* Add some space at the top */
    margin-left: 0; /* Reset any left margin */
  }

  .input-wrapper-1 {
    position: absolute; /* Keep if you need the input to be absolutely positioned */
    width: 80%; /* Adjust the width to fit smaller screens better */
    top: 20px; /* Adjust the top position to not overlap with other elements */
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Correct horizontal centering */
    margin-top: 6rem; /* Add some space at the top */
    margin-left: 0; /* Reset any left margin */
  }
  .input-wrapper-2 {
    position: absolute; /* Keep if you need the input to be absolutely positioned */
    width: 80%; /* Adjust the width to fit smaller screens better */
    top: 20px; /* Adjust the top position to not overlap with other elements */
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Correct horizontal centering */
    margin-top: 10rem; /* Add some space at the top */
    margin-left: 0; /* Reset any left margin */
  }

  .input-wrapper-3 {
    position: absolute; /* Keep if you need the input to be absolutely positioned */
    width: 80%; /* Adjust the width to fit smaller screens better */
    top: 20px; /* Adjust the top position to not overlap with other elements */
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Correct horizontal centering */
    margin-top: 14rem; /* Add some space at the top */
    margin-left: 0; /* Reset any left margin */
  }
  .first-name-input {
    width: 90%; /* Ensure the input expands to fill the wrapper */
  }

  .already-member {
    position: absolute;
    width: 80%; /* Full width to ensure it's centered */
    top: auto; /* Adjust based on content */
    left: 50%; /* Center align */
    transform: translateX(-50%); /* Correct horizontal centering */
    margin-top: 26.5rem; /* Reset any top margin */
    font-size: 14px; /* Adjust font size for mobile readability */
    margin-left: 0; /* Ensure it's properly centered */
  }

}


/* This media query is for tablets */
@media (min-width: 601px) and (max-width: 1024px) {
  /* Adjust the logo container for tablet screens */
  .bg{
    background-size: cover;
    min-height: 100vh; 
    padding: 0;
    margin: 0;
  }

  .logo-container {
    top: 0%;
    right: 50%;
    transform: translateX(-50%); /* Correct adjustment for centering */
    width: auto; /* Adjust width as necessary */
    align-items: center;
  }
  
  .logo-container {
    position: relative; /* Adjusted if needed */
    text-align: center;
    right: 0; /* Resets any right positioning */
    top: 0; /* Keep at the top or adjust as needed */
    transform: none; /* Remove any transformations */
    width: auto; /* Adjust width as necessary */
  }

  /* Adjust the size of the logo and heading for tablet screens */
  .logo-container h3 {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem; /* Adjust as needed */
  }

  .create-new-account {
    width: 10%; /* Adjust width to not be full but close for smaller screens */
    flex-basis: auto;
    align-items: center; /* Ensure content is centered */
  }
  
  .headingStyle {
    margin-top: 0 rem; /* Adjust top margin to reduce gap */
    font-size: 2.8rem; /* Adjust font size for readability */
    text-align: center; /* Ensure text is centered */
    width: auto; /* Auto width for flexibility */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow if text is too long */
    text-overflow: ellipsis; /* Add an ellipsis if text overflows (optional) */
    top: 0%;
  }

  .already-member {
    margin-top: 12 rem; /* Adjust top margin to reduce gap */
    font-size: 1.3rem; /* Adjust font size for readability */
    text-align: center; /* Ensure text is centered */
    width: auto; /* Auto width for flexibility */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow if text is too long */
    text-overflow: ellipsis; /* Add an ellipsis if text overflows (optional) */
  }
}

