// .event-details-banner-container {
//   margin-right: auto;
//   margin-left: auto;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   max-width: 1400px;
// }

// .event-details-banner {
//   position: relative;
//   font-family: var(--px-primary-font);
//   color: var(--px-white);
//   padding: 3rem;
//   width: 100%;
//   max-width: 1400px;
//   height: 80vh;
//   // max-height: 500px;
//   min-height: 400px;
//   background-color: var(--px-white);
//   display: flex;
//   justify-content: space-between;
//   margin: 2rem;
//   border-radius: 1rem;

//   @include down-sm {
//     flex-direction: column;
//     padding: 1.5rem;
//     height: auto;
//   }

//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: inherit;
//     background-size: cover;
//     background-position: center;
//     filter: brightness(0.5); /* Adjust the filter as needed */
//     z-index: 0;
//     border-radius: 1rem;
//   }

//   .event-image {
//     display: none;
//     @include down-sm {
//       margin-top: 1rem;
//       display: block;
//       width: 100%;
//       z-index: 1000;
//       // height: auto;

//       // object-fit: cover;
//       margin-bottom: 20px;
//       img {
//         border-radius: 1rem;
//       }
//     }
//   }

//   .left-side,
//   .right-side {
//     position: relative;
//     z-index: 1;
//   }

//   .left-side {
//     flex: 1.5;
//     padding-right: 20px;

//     .heading {
//       font-size: 3rem;
//       font-weight: 800;

//       @include down-sm {
//         font-size: 2rem;
//       }
//     }

//     .subheading {
//       font-size: 1.5rem;
//       margin-bottom: 10px;

//       @include down-sm {
//         font-size: 1rem;
//       }
//     }

//     .view-map-btn {
//       padding: 10px 20px;
//       font-size: 1rem;
//       background-color: #007bff;
//       color: white;
//       border: none;
//       border-radius: 4px;
//       cursor: pointer;

//       @include down-sm {
//         font-size: 0.75rem;
//       }
//     }
//   }

//   .right-side {
//     display: flex;
//     flex-direction: column;
//     justify-content: end;
//     flex: 1;
//     color: black;

//     .nested-banner {
//       font-family: "Inconsolata";
//       background-color: #fff;
//       padding: 20px;
//       border-radius: 8px;
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

//       .date-time-heading {
//         font-size: 1.5rem;
//         font-weight: 700;

//         @include down-sm {
//           font-size: 1.25rem;
//         }
//       }

//       .details {
//         font-size: 1rem;
//         margin-bottom: 20px;
//         color: var(--px-gray);

//         @include down-sm {
//           font-size: 0.75rem;
//         }
//       }

//       .book-now-btn,
//       .view-details-btn {
//         display: block;
//         width: 100%;
//         padding: 0.75rem 2rem;
//         border: none;
//         border-radius: 5px;
//         cursor: pointer;
//         color: var(--px-white);
//         text-align: center;
//       }

//       .book-now-btn {
//         background-color: var(--px-purple);
//         margin-bottom: 1rem;
//       }

//       .view-details-btn {
//         background-color: var(--px-gray);
//       }
//     }
//   }
// }

.event-details-banner-container {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1400px;
  aspect-ratio: 16 / 9;
  overflow: hidden;

  @include down-sm {
    aspect-ratio: auto;
  }
}

.event-details-banner {
  position: relative;
  font-family: var(--px-primary-font);
  color: var(--px-white);
  padding: 3rem;
  width: 100%;
  max-width: 1400px;
  aspect-ratio: 16 / 9;
  // height: 100%;
  min-height: 400px;
  background-color: var(--px-white);
  display: flex;
  justify-content: space-between;
  margin: 2rem;
  border-radius: 1rem;
  overflow: hidden;

  @include down-sm {
    flex-direction: column;
    padding: 1.5rem;
    height: auto;
    aspect-ratio: auto;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    filter: brightness(0.5); /* Adjust the filter as needed */
    z-index: 0;
    border-radius: 1rem;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    filter: brightness(0.5); /* Adjust the filter as needed */
    border-radius: 1rem;
  }

  .event-image {
    display: none;
    @include down-sm {
      margin-top: 1rem;
      display: block;
      width: 100%;
      aspect-ratio: 16 / 9;
      z-index: 500;
      margin-bottom: 20px;

      img,
      video, iframe {
        border-radius: 1rem;
      }
    }
  }

  .left-side,
  .right-side {
    position: relative;
    z-index: 1;
  }

  .left-side {
    flex: 1.5;
    padding-right: 20px;

    .heading {
      font-size: 3rem;
      font-weight: 800;

      @include down-sm {
        font-size: 2rem;
      }
    }

    .subheading {
      font-size: 1.5rem;
      margin-bottom: 10px;

      @include down-sm {
        font-size: 1rem;
      }
    }

    .view-map-btn {
      padding: 10px 20px;
      font-size: 1rem;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      @include down-sm {
        font-size: 0.75rem;
      }
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex: 1;
    color: black;

    .nested-banner {
      font-family: "Inconsolata";
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      .date-time-heading {
        font-size: 1.5rem;
        font-weight: 700;

        @include down-sm {
          font-size: 1.25rem;
        }
      }

      .details {
        font-size: 1rem;
        margin-bottom: 20px;
        color: var(--px-gray);

        @include down-sm {
          font-size: 0.75rem;
        }
      }

      .book-now-btn,
      .view-details-btn {
        display: block;
        width: 100%;
        padding: 0.75rem 2rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        color: var(--px-white);
        text-align: center;
      }

      .book-now-btn {
        background-color: var(--px-purple);
        margin-bottom: 1rem;
      }

      .view-details-btn {
        background-color: var(--px-gray);
      }
    }
  }
}
