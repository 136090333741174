html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;  
}

.head-text {
    position: relative;
    flex-basis: 50%;
    margin: 0;
    height: auto;
    font-size: 1.6rem;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
    z-index: 2;
    padding-bottom: 0;
}

.style {
    color: var(--Neutral-10, #FFF);
    font-family: 'Poppins', sans-serif;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin-top: 10rem;
    margin-left: 47rem;  
    align-self: flex-start;  
    width: auto;
    grid-column: 1 / -1;
    white-space: pre-wrap;
    order: 1;
}

.subHeading {
    color: var(--Neutral-10, #FFF);
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 47rem;  
    align-self: flex-start;  
    width: auto;
    grid-column: 1 / -1;
    white-space: pre-wrap;
    order: 2;
}



.login-form {
    margin-top: 1rem;
    min-height: 50px; /* Adjust based on typical size with error messages */
    width: 100%; /* Ensure it fills the container */
    margin-bottom: 20px; /* Ensure space between form and button */

      
}

.break-on-desktop {
    display: block;    
 }

 
 .auth-links {
    display: flex;
    align-items: center; /* Aligns items vertically centered */
    justify-content: space-between; /* Distributes space between and around content items */
    color: var(--Neutral-40, #E0E0E0);
    font-family: Poppins;
    font-size: 16px;
    margin-top: 15px; /* Adjust as needed */
  }
  
  .not-member, .forgot-password {
    margin-left: 10px; /* Ensure there’s some spacing */
  }
  
  .signup-link, .password-link {
    color: #E8364E;
    text-decoration: none; /* Initially no underline */
  }
  
  .signup-link:hover, .password-link:hover {
    text-decoration: underline; /* Underline on hover for interaction feedback */
  }


  
  

 /* Mobile screens: Center all content */
@media (max-width: 600px) {
    .head-text {
        flex-basis: 90%;
        font-size: 1.2rem;
        align-items: center;  /* Center aligns all flex children */
    }

    .style {
        font-size: 1.5rem;
    }
    
    .style, .subHeading, .login-form {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;  /* Consistent margin for top */
        width: 90%;  /* Use full width to ensure centering */
        font-size: 1.25rem; /* Adjust font size for mobile */
    }

    .subHeading {
        font-size: 1rem; /* Smaller font size for subheading */
        margin-top: 1.25rem; /* Slightly larger top margin for spacing */
    }

    .break-on-desktop {
        display: none;    
    }

    .auth-links {
        flex-direction: column; /* Stack items vertically */
        margin-top: 10px; /* Add a bit of margin on top for spacing */
        text-align: center;
        margin-left: auto;
        margin-right: auto;
   
    }

    .not-member, .forgot-password {
        width: 100%; /* Full width for better touch targets */
        margin-bottom: 10px; /* Add space between the links */
    }


}

/* Tablet screens: Center all content */
@media (min-width: 601px) and (max-width: 1024px) {
    .head-text {
        align-items: center; /* Center aligns all flex children */
    }

    .style {
        font-size: 2rem;
    }

    .style, .subHeading, .login-form {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: auto; /* Auto width might be sufficient if content fills correctly */
        font-size: 1.5rem; /* Slightly larger font for tablet than mobile */
        margin-top: 0.65rem;
    }

    .subHeading {
        font-size: 1.2rem; /* Adjusted for readability on tablets */
        margin-top: 0.5rem;
    }


    .break-on-desktop {
        display: none;
    }



}

@media (min-width: 1025px) {  
    .head-text, .style, .subHeading {
        max-height: 90vh;  
        overflow-y: auto;  
    }
}

