.event-banner-container {
  // z-index: 900;
  font-family: var(--px-primary-font);
  color: var(--px-white);
  // margin-top: 2rem;
  padding: 2rem;
  width: 100%;
  max-width: 1400px;
  // height: 80vh;
  // max-height: 500px;

  // min-height: 60vh;
  background-color: var(--px-white);
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;

  .carousel-container {
    width: 100%;
    padding: 1rem 0rem;
    .carousel {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    .slide {
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      display: flex;
      // flex-direction: column;
      // align-items: start;
      // justify-content: center;
      @media (min-width: 1400px) {
        border-radius: 1rem;
      }
      // padding: 4rem;
      // @include down-xsm {
      //     padding: 2rem;
      // }
      h6 {
        width: 70%;
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 2rem;
        @include down-md {
          font-size: 3rem;
        }
        @include down-xsm {
          font-size: 2rem;
          width: 100%;
        }
      }
    }
    .slide-hidden {
      display: none;
    }
    // @include down-xsm {
    //     width: 100%;
    //     .slide {
    //         border-radius: 0;
    //     }
    //}
    // .arrow {
    //     position: absolute;
    //     filter: drop-shadow(0px 0px 5px #555);
    //     width: 2rem;
    //     height: 2rem;
    //     color: white;
    // }
    // .arrow:hover {
    //     cursor: pointer;
    // }

    // .arrow-left {
    //     left: 1rem;
    // }

    // .arrow-right {
    //     right: 1rem;
    // }

    .indicators {
      display: flex;
      position: absolute;
      bottom: 1rem;
    }

    .indicator {
      background-color: white;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 100%;
      border: none;
      outline: none;
      box-shadow: 0px 0px 5px #555;
      margin: 0 0.2rem;
      cursor: pointer;
    }

    .indicator-inactive {
      background-color: grey;
    }
  }
}

.loading-box-container {
  margin: 0 2rem;
  .loading-box {
    height: 50vh;
    background-color: rgb(226, 226, 226);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 1400px;
    margin: 2rem auto;
    border-radius: 20px;
    font-size: 2rem;
    font-family: "Inconsolata";
    font-weight: 700;
  }
}
