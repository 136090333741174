.payment-flow-container {
  font-size: 1.5rem;
  padding: 4rem 2rem;
  max-width: 1400px;
  margin: auto;
  .payment-navigator {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    .back-button {
      border: 3px solid var(--px-purple);
      border-radius: 5px;
      padding: 5px 2px 5px 5px;
    }
  }
}

.payment-next-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  .payment-next-step-button {
    font-size: 1rem;
    color: black;
    display: inline-flex;
    background-color: var(--px-purple);
    // color: var(--px-white);
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }
  .payment-next-step-button-disabled {
    opacity: 0.5;
    cursor: default;
  }
}

// select tickets
.purchase-event-information-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2rem 0;
  .purchase-event-image {
    position: relative;
    width: 50%;
    aspect-ratio: 16/9;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  .purchase-event-details {
    width: 50%;
    padding-left: 2rem;
    .basic-information {
      margin-bottom: 2rem;

      .heading {
        font-size: 1.5rem;
        font-weight: 500;
        //   margin-bottom: 10px;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }

      .location-section,
      .description {
        font-size: 1rem;
        // margin-bottom: 10px;
      }

      .location-section {
        color: black;
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
        img {
          margin-right: 0.5rem;
        }
      }
    }
  }

  @include down-sm {
    flex-direction: column;
    align-items: center;
    .purchase-event-image,
    .purchase-event-details {
      width: 100%;
    }
    .purchase-event-details {
      padding-left: 0;
    }
  }
}

.horizontal-line {
  padding: 0 0.5rem 1rem 0.5rem;
}

.ticket-options-container {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  text-align: center;
  .ticket-option-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 1.5rem;
    border-radius: 5px;
    width: calc(100% / 4 - 80px / 3);
    // height: 100%;
    @include down-lg {
      width: calc(100% / 3 - 60px / 2);
    }
    @include down-md {
      width: calc(100% / 3 - 40px / 3);
    }
    @include down-sm {
      width: calc(100% / 2 - 40px / 2);
    }
    @include down-xsm {
      width: 100%;
    }

    .ticket-section-top {
      flex: 3;
      padding-bottom: 0.75rem;
      .ticket-type {
        font-size: 1.25rem;
        padding-bottom: 0.5rem;
      }
      .ticket-description {
        font-size: 0.75rem;
      }
    }

    .ticket-section-bottom {
      // padding-bottom: 1.5rem;
      flex: 1;
      .ticket-price {
        font-size: 1.25rem;
        font-weight: 500;
        padding-bottom: 0.75rem;
      }
      .ticket-quantity-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .ticket-quantity {
          font-size: 1.25rem;
          font-weight: 700;
        }
        .ticket-quantity-zero {
          color: grey;
        }
      }
      .ticket-quantity-control-button {
        border: 2px solid var(--px-purple);
        padding: 0.3rem;
        border-radius: 5px;
      }
    }

    &:hover,
    &:not(:hover) {
      -webkit-box-shadow: 10px 10px 0px 0px var(--px-purple);
      -moz-box-shadow: 10px 10px 0px 0px var(--px-purple);
      box-shadow: 10px 10px 0px 0px var(--px-purple);
      border: 1px solid var(--px-purple);
      transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
    }

    &:not(:hover) {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: 1px solid #ccc;
    }
  }
}

// order summary {
.purchase-order-summary-container {
  padding-left: 1rem;
  padding-top: 1.5rem;
  font-size: 1rem;
  border-left: 1px solid #ccc;
  h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  width: calc(50% - 1rem);
  @include down-sm {
    width: 100%;
    border-left: none;
    border-bottom: 1px solid #ccc;
  }
  .purchase-event-details {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 1rem;
    .event-image {
      width: 35%;
      aspect-ratio: 16/9;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0.5rem;
    }
    .basic-information {
      width: calc(65% - 15px);
      padding-left: 15px;

      .heading {
        font-weight: 500;
      }
      .location-section {
        display: flex;
        gap: 5px;
      }
    }
  }
  .summary-container {
    border-bottom: 1px dashed #ccc;
    margin-bottom: 1rem;
    // padding-bottom: 1rem;
  }
  .summary-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }
}

// contact information
.purchase-contact-and-order-summary {
  display: flex;
  flex-direction: row;

  @include down-sm {
    flex-direction: column-reverse;
  }
  // gap: 2rem;
  .purchase-contact-information-container {
    margin-right: 1rem;
    width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;

    @include down-sm {
      width: 100%;
    }
    .purchase-info-banner {
      margin-bottom: 20px;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @include down-xsm {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
      }
    }
    .input-container {
      width: calc(50% - 10px);
      @include down-xsm {
        width: 100%;
      }
      input {
        font-size: 0.8rem;
        width: 100%;
        background-color: #f9f9f9;
        border: none;
        border-radius: 0.5rem;
        height: 2.5rem;
      }
      .input-label {
        font-size: 0.75rem;
        margin-bottom: 5px;
      }
    }
  }
}

// payment method
.purchase-payment-method-and-order-summary {
  display: flex;
  flex-direction: row;
  @include down-sm {
    flex-direction: column-reverse;
  }
  .purchase-payment-method-container {
    margin-right: 1rem;
    width: calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0;
    h2 {
      font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    }
    @include down-sm {
      width: 100%;
    }
  }
  .payment-selection-option-container {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    .payment-selection-option {
      width: 100%;
      padding: 0.5rem 1rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      // background-color: red;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .payment-selection-option-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
      }
      .payment-selection-option-icon {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover,
      &:not(:hover) {
        -webkit-box-shadow: 7px 7px 0px 0px var(--px-purple);
        -moz-box-shadow: 7px 7px 0px 0px var(--px-purple);
        box-shadow: 7px 7px 0px 0px var(--px-purple);
        border: 1px solid var(--px-purple);
        transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
      }

      &:not(:hover) {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border: 1px solid #ccc;
      }
    }

  }
}

// payment instructions
.purchase-payment-instructions-and-order-summary {
  display: flex;
  flex-direction: row;
  @include down-sm {
    flex-direction: column-reverse;
  }
  .purchase-payment-instruction-container {
    padding-top: 2rem;
    width: calc(50% - 1rem);
    @include down-sm {
      width: 100%;
    }
    .heading {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .invoice-number {
      font-size: 1.25rem;
      color: #E8364E;
      text-align: center;
    }
    .payment-instructions-container {
      .payment-instructions-heading {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
      }
      .payment-instructions-text {
        font-size: 1rem;
        // margin-bottom: 0.5rem;
      }
    }
    .input-container {
      width: calc(50% - 10px);
      margin-top: 1rem;
      input {
        font-size: 0.8rem;
        width: 100%;
        background-color: #f9f9f9;
        border: none;
        border-radius: 0.5rem;
        height: 2.5rem;
      }
      .input-label {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .error-text {
        font-size: 0.75rem;
        color: red;
        padding-top: 5px;
      }
    }
    .receipt-upload-container {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      .receipt-upload-label {
        cursor: pointer; 
      }
      .receipt-upload-label-success {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
      .receipt-file-name {
        font-size: 1rem;
      }
      .file-name {
        font-size: 1rem;
      }
      .error-text {
        font-size: 0.75rem;
        color: red;
        padding-top: 5px;
      }
    }
    .payment-instructions-actions-button-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 1rem;
      font-weight: 500;
      padding-right: 1rem;
      padding-top: 1rem;
      .payment-instructions-confirm-button {
        background-color: var(--px-purple);
        border-radius: 0.5rem;
        padding: 0.5rem;
        color: var(--px-white);
      }
      .payment-instructions-cancel-button {
        background-color: var(--px-white);
        border: 1px solid var(--px-purple);
        border-radius: 0.5rem;
        padding: 0.5rem;
        color: var(--px-purple);
      }
    }
  }
}
