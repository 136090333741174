.account-page-container {
  overflow: visible;
  display: flex;
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @include down-xsm {
    flex-direction: column;
  }

  .vertical-sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px;

    @include down-xsm {
      flex-direction: row;
      padding: 0px 16px;
    }
  }

  .icon-button {
    background: none;
    border: none;
    color: #ddd;
    padding: 15px 25px;
    margin: 10px 0 0 0;
    cursor: pointer;
    transition: color 0.3s;
  }

  .icon-button.active {
    background: rgb(65, 130, 249);
    background: linear-gradient(
      90deg,
      rgba(65, 130, 249, 0.15682769689907217) 0%,
      rgba(65, 130, 249, 0.6498248957786239) 100%
    );
  }

  // .icon-button.active {
  //   color: #61dafb;
  // }

  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 0 16px 16px 0;

    @include down-xsm {
      border-radius: 0 0 16px 16px;
    }

    .greeting {
      .heading {
        font-family: var(--px-primary-font);
        font-weight: 500;
        font-size: 1.25rem;
        @include down-xsm {
          font-size: 1rem;
        }
      }

      .current-date {
        color: #ada7a7;
        font-size: 0.9rem;
        @include down-xsm {
          font-size: 0.75rem;
        }
      }
    }

    .account-container,
    .payment-container,
    .contact-container {
      // height: 300px;
      background-color: #fff;
      border-radius: 12px;
      padding: 2rem;
      margin-top: 1rem;
    }

    .payment-container {
      background-image: url("../../../public/payment-overlay.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 600px;
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        font-size: 2rem;
        font-weight: 600;
      }

      @include down-sm {
        height: 300px;
      }
    }
  }
}

// account details tab
.account-details-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 20px;
  padding-left: 15px;
}

.basic-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .profile-pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  .personal-details {
    .name {
      font-weight: 500;
    }
    .email {
      color: var(--px-gray);
      font-size: 0.8rem;
    }
  }
}

.account-details {
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
  .input-container {
    width: calc(50% - 10px);
    input {
      font-size: 0.8rem;
      width: 100%;
      background-color: #f9f9f9;
      border: none;
      border-radius: 0.5rem;
      height: 2.5rem;
    }
    .input-label {
      font-size: 0.75rem;
      margin-bottom: 5px;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: calc(50% - 10px);
    .input-label {
      font-size: 0.75rem;
      margin-bottom: 5px;
    }

    .inactive {
      color: var(--px-gray);
    }
  }

  .dropdown-toggle {
    width: 100%;
    background-color: #f9f9f9;
    height: 2.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 0.8rem;

    .arrow {
      color: var(--px-gray);
    }
  }

  .dropdown-toggle .arrow {
    margin-left: 10px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f9f9f9;
    // border: 1px solid var(--px-gray);
    border-radius: 5px;
    margin-top: 5px;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensures dropdown is on top */
    max-height: 200px; /* Adjust the height for the scrollable area */
    overflow-y: auto; /* Enables scrolling if the content is too long */
    font-size: 0.8rem;
  }

  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  .email-details-container {
    .heading {
      font-weight: 500;
    }
    .email-main-content {
      padding-top: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .email-icon {
        padding-right: 0.75rem;
      }

      .email-field {
        display: flex;
        font-size: 0.8rem;
        .email {
          padding-right: 0.25rem;
        }
        button {
          color: var(--px-theme);
        }
      }
      .history {
        font-size: 0.8rem;
        color: var(--px-gray);
      }
    }
  }

  .reset-pass-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }

  .reset-button {
    font-size: 0.85rem;
    background-color: var(--px-blue-light);
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    color: var(--px-white);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
}

// contact us tab
.contact-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h6 {
    font-size: 1rem;
  }

  .contact-button {
    font-size: 0.85rem;
    background-color: var(--px-blue-light);
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    color: var(--px-white);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
}
.contact-headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  h6 {
    font-weight: 600;
    font-size: 3rem;

    @include down-md {
      font-size: 2rem;
    }
    @include down-xsm {
      font-size: 1.5rem;
    }
  }
  .contact-social-icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 2rem; 

    img {
      cursor: pointer;
      border: 1px solid var(--px-gray);
      border-radius: 2.5rem;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.75rem;
      margin-top: 0.75rem;
    }
  }

  @include down-md {
    flex-direction: column;

    .contact-social-icons {
      flex-direction: row;
      margin-right: 0;

      img {
        margin-right: 0.5rem;
      }
    }
  }

}
.contact-form {
  margin-top: 20px;
  .input-container {
    width: 50%;
    margin-bottom: 15px;
    input,
    textarea {
      font-size: 0.8rem;
      width: 100%;
      background-color: #f9f9f9;
      border: none;
      border-radius: 0.5rem;
      height: 2.5rem;
    }
    .input-label {
      font-size: 0.75rem;
      margin-bottom: 5px;
    }

    @include down-sm {
      width: 100%;
    }
  }
  .message {
    width: 100%;
  }
}
.contact-footer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .footer-headline {
    font-weight: 600;
    font-size: 2rem;

    @include down-md {
      font-size: 1.25rem;
    }
  }

  .contact-button {
    font-size: 0.85rem;
    background-color: var(--px-blue-light);
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    color: var(--px-white);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

    @include down-md {
      padding: 0.5rem 1rem;
      font-size: 0.5rem;
    }
  }

  @include down-xsm {
    flex-direction: column;
    .contact-button {
      margin-top: 0.5rem;
    }
  }
}
