footer {
    width: 100vw;
    background-color: var(--px-blue-dark);
    backdrop-filter: blur(10px);
    padding-top: 30px;
    margin-top: 5rem;
    font-family: $px-primary-font;
    @include down-xsm {
      //padding: 30px 30px 0px 30px;
      width: 100%;
    }
  }
  .footer {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 5rem;
  
    @include down-md {
      display: block;
      text-align: center;
    }
  
    .heading {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 1rem;
      @include down-xsm {
        font-size: 0.75rem;
        margin-bottom: 5px;
      }
    }
  
    .logo-container {
      width: auto;
    }
  
    .logo {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
      h6 {
        font-size: 2rem;
        font-weight: 700;
        color: var(--px-white);
      }
    }
    @media (max-width: 768px) {
      .logo {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  
    .all-links {
      display: flex;
      justify-content: space-between;
      @include down-md {
        padding-top: 2rem;
        display: block;
        margin-right: auto;
        margin-left: auto;
      }
    }
  
    .links {
      margin-right: 3rem;
      @include down-md {
        margin-right: 0;
      }
    }
  
    .links {
      ul {
        list-style: none;
        padding: 0;
        @include down-xsm {
          margin-bottom: 10px;
        }
  
        li {
          margin-bottom: 1rem;
  
          a {
            color: var(--px-white);
            font-family: $px-primary-font;
            font-weight: 700;
            text-decoration: none;
            // @include down-xsm {
            //   font-size: 0.75rem;
            // }
          }
        }
      }
    }

    .information {
        td {
            padding-bottom: 1rem;
        }
        .table-icons {
            width: 2rem;
        }
        .table-headings {
            padding-right: 2rem;
        }
        img {
            padding-right: 0.75rem;
        }
        @include down-md {
            table {
                margin-top: 2rem;
                margin-right: auto;
                margin-left: auto;
                font-size: 0.75rem;
            }
        }
    }
  
    li a {
      position: relative;
      cursor: pointer;
      transition: all 0.4s ease;
      text-transform: capitalize;
  
      &:hover {
        color: var(--px-white);
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 1px;
        bottom: 0;
      }
  
      &:after {
        right: 2.5px;
        background: var(--px-blue-light);
        transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:before {
        background: var(--px-blue-light);
        width: 100%;
        transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
      }
      &:hover:after {
        background: transparent;
        width: 100%;
        transition: 0s;
      }
    }
    /* Added by Umar */
    @media (max-width: 768px) {
      .information {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center-align rows within the container */
        padding: 0 1rem; /* Add padding to prevent touching the border */
      }
    
      .information table {
        width: 100%;
        max-width: 100%; /* Ensure table does not exceed container width */
        border-collapse: collapse;
        margin: 0; /* Remove any default margin */
      }
    
      .information tr {
        display: grid;
        grid-template-columns: auto 1fr auto; /* Adjust columns to fit icon, label, and value */
        gap: 1rem; /* Space between columns */
        align-items: center;
        justify-content: center; /* Center-align the entire row */
        padding: 0.5rem 0;
        border: none; /* Remove borders if any */
        width: 100%; /* Ensure rows take full width for centering */
      }
    
      .information td {
        padding: 0 1rem; /* Add padding to each cell for spacing */
      }
    
      .information .table-icons {
        grid-column: 1;
        text-align: left; /* Align icon to the left */
        display: flex;
        align-items: center; /* Center icons vertically within the cell */
      }
    
      .information .table-headings {
        grid-column: 2;
        text-align: left; /* Align label to the left */
      }
    
      .information td:nth-child(3) {
        grid-column: 3;
        text-align: right; /* Align value to the right */
      }
    
      .information img {
        max-width: 2rem; /* Ensure icons are not too large */
        height: auto; /* Maintain aspect ratio of icons */
      }
    }
    
    /* Remove if causes problems */
  }
  .copyright {
    text-align: center;
    background-color: var(--px-blue-light);
    color: var(--px-white);
    padding: 0.5rem;
    span {
       font-weight: 700; 
    }
  }
  /* Base styling for the value column */
table tr td:nth-child(3) {
  position: relative;
  color: inherit;
  display: inline-block; /* Align the underline with the text width */
}

/* Adding the underline using ::after */
table tr td:nth-child(3)::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 18px; /* Adjust the underline position upward */
  width: 0;
  height: 2px;
  background-color: #007bff; /* The color of the underline */
  transition: width 0.3s ease-in-out;
  display: block; /* Ensure the underline takes up only the width of the text */
}

/* Hover effect for changing text color and growing underline */
table tr:hover td:nth-child(3) {
  color: #007bff;
}

table tr:hover td:nth-child(3)::after {
  width: 100%; /* The underline will now grow only to the width of the text */
}
