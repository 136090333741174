:root {
    --bs-heading-color: var(--px-heading);
    --px-theme: #{$px-theme};
    --px-theme-rgb: #{$px-theme-rgb};
    --px-black: #{$px-black};
    --px-white: #{$px-white};
    --px-text: #{$px-text};
    --px-heading: #{$px-heading};
    --px-bg: #{$px-bg};
    --px-turquoise: #{$px-turquoise};
    --px-pink: #{$px-pink};
    --px-blue-dark: #{$px-blue-dark};
    --px-blue-medium: #{$px-blue-medium};
    --px-blue-light: #{$px-blue-light};
    --px-gray: #{$px-gray};
    --px-purple: #{$px-purple};

    --px-primary-font: #{$px-primary-font};
  }
  