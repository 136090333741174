.event-card-container {
  cursor: pointer;
  // width: calc(100% / 3 - 60px / 3);
  // aspect-ratio: 1/1;
  flex-shrink: 0;
  width: calc(100% / 4 - 80px/3);
  @include down-lg {
    width: calc(100% / 3 - 60px / 2);
    // aspect-ratio: 1/1;
  }

  @include down-md {
    width: calc(100% / 3 - 40px / 3);
    // aspect-ratio: 1/1;
  }
  // @include down-sm {
  //   width: calc(100% / 2 - 40px / 2);
  //   // aspect-ratio: 1/1;
  // }
  @include down-sm {
    width: 100%;
    // aspect-ratio: 1/1;
  }
  
}

.event-card-container-home {
  cursor: pointer;
  // width: calc(100% / 3 - 60px / 3);
  // aspect-ratio: 1/1;
  flex-shrink: 0;
  min-width: calc(100% / 4 - 80px/3);
  width: calc(100% / 4 - 80px/3);
  @include down-lg {
    width: calc(100% / 3 - 60px / 2);
    // aspect-ratio: 1/1;
  }

  @include down-md {
    width: calc(100% / 3 - 40px / 3);
    // aspect-ratio: 1/1;
  }
  // @include down-sm {
  //   width: calc(100% / 2 - 40px / 2);
  //   // aspect-ratio: 1/1;
  // }
  @include down-sm {
    width: 100%;
    // aspect-ratio: 1/1;
  }
  
}
.event-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;

  .card-image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit:cover;
    // height: auto;
  }

  .card-details {
    padding: 1rem;
    display: flex;

    .date {
      font-size: 0.85rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }

    .content {
      margin-top: 10px;

      .heading {
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: capitalize;
        color: var(--px-purple);
      }

      .name {
        font-size: 1rem;
        text-transform: capitalize;
        //   margin-top: 3px;
        font-weight: 500;
      }

      .price {
        font-size: 0.85rem;
        //   margin-top: 3px;
      }
      .location-section {
        display: flex;

        .location {
          font-size: 0.85rem;
          text-transform: capitalize;
          //   margin-top: 3px;
        }
      }
    }
  }
  &:hover,
  &:not(:hover) {
    -webkit-box-shadow: 10px 10px 0px 0px var(--px-purple);
    -moz-box-shadow: 10px 10px 0px 0px var(--px-purple);
    box-shadow: 10px 10px 0px 0px var(--px-purple);
    border: 1px solid var(--px-purple);
    transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
  }

  &:not(:hover) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ccc;
  }
}