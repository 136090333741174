.city-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;

  .city-cards-header {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: flex-end;
    justify-content: right;
    .see-all-button {
      font-family: var(--px-primary-font);
      color: var(--px-purple);
      font-size: 1rem;
      font-weight: 700;
      padding-bottom: 0.5rem;
      text-align: right;
    } 

    @include down-sm {
      display: none;
    }
  }


}
.city-cards {
  width: 100%;
  max-width: 1400px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include down-sm {
    display: none;
  }

}
.city-cards .grey {
  filter: grayscale(100%);
  opacity: 0.5;
}
.city-card {
  width: calc(100vw / 6 - 20px); /* Adjust width as needed */
  height: calc(100vw / 6 - 20px); /* Adjust height as needed */
  max-width: 210px;
  max-height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-size: cover;
  position: relative; /* Needed for the overlay */

  &:hover {
    transform: scale(1.05);
  }

  &__heading {
    font-family: var(--px-primary-font);
    color: var(--px-white);
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    position: relative;
    // z-index: 2; /* Ensures the text is above the overlay */

    @include down-md {
      font-size: 1rem;
    }
  }

  &__overlay {
    content: "Coming Soon";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.6); /* Translucent gray */
    opacity: 0;
    transition: opacity 0.3s ease;
    // z-index: 1; /* Ensures the overlay is below the text */
    border-radius: 10px; /* Matches the card's border-radius */
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--px-white);
    font-family: var(--px-primary-font);
    font-weight: 700;
    font-size: 1.5rem;

    @include down-md {
      font-size: 1rem;
    }
  }

  &.city-card-disabled:hover .city-card__overlay {
    opacity: 1;
  }

  &.city-card-disabled:hover .city-card__heading {
    opacity: 0;
  }
}

.city-card-active {
  padding: 5px;
  border-radius: 15px;
  border: 3px solid var(--px-purple);
}

.city-card-disabled {
  cursor: not-allowed;

  &__heading {
    color: var(--px-white); /* Ensures the text remains visible */
  }
}
