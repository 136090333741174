.purchase-confirmation-page-container {
  // width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem;

  .confirmation-illustration {
    width: 250px;
    height: auto;
  }
  .heading {
    font-size: 1rem;
    font-weight: 500;
    padding-top: 1rem;
    .contact-us-redirect {
      color: var(--px-purple);
      text-decoration: none;
    }
  }
  .email {
    font-size: 1rem;
    color: var(--px-purple);
    cursor: pointer;
  }
  .purchase-confirmation-details {
    background-color: #f9f9f9;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 1rem;
    img {
        margin:auto;
    }
    .download-e-ticket-button {
      background-color: var(--px-purple);
      color: var(--px-white);
      border-radius: 0.5rem;
      padding: 0.5rem 2rem;
      margin-top: 0.5rem;
    }
    .purchase-status-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      background-image: url("../../../public/ticket-overlay.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 1rem;
      margin-top: 1rem;
      .status-button {
        display: inline;
        border: 1px solid black;
        border-radius: 0.5rem;
        padding: 0.75rem 2rem;
      }
      .date-time {
        display: flex;
        gap: 0.5rem;
      }
    }
    .amount-paid {
      font-size: 1.5rem;
      color: var(--px-purple);
    }
    .cancel-booking-button {
      border: 1px solid #900b09;
      color: #900b09;
      font-weight: 500;
      font-size: 1rem;
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      margin: 0.5rem;
    }
    .resend-tickets-button {
      border: 1px solid var(--px-purple);
      color: var(--px-purple);
      font-weight: 500;
      font-size: 1rem;
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      margin: 0.5rem;
    }
    .resend-tickets-button-disabled {
      color: var(--px-purple);
      font-weight: 500;
      font-size: 1rem;
      padding: 0.5rem 2rem;
      border-radius: 0.5rem;
      margin: 0.5rem;
    }
    .contact-details {
      div {
        margin-top: 0.5rem;
        display: flex;
        gap: 1.5rem;
        color: var(--px-purple);
        h6 {
          cursor: pointer;
        }
      }
    }
  }
}
