@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$px-font: "Inter", sans-serif !default;
$px-primary-font: "Poppins", sans-serif !default;
// Theme Variable

$px-theme: #0788ff;
$px-theme-rgb: 7, 136, 255;
$px-blue-dark: #04092C;
$px-blue-medium: #10107B;
$px-blue-light: #2C9CF0;
$px-white: #fff;
$px-black: #040c16;
$px-bg: #fff;
$px-turquoise: #377871;
$px-pink: #855676;
$px-gray: #999999;
$px-purple: #7848F4;


$px-text: #fff;
$px-heading: #fff;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;
