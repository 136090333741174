.coroselContainer{
    display: flex;
    justify-content: center;
    margin: 0 15rem 1rem 15rem;
    border-radius: 2rem 2rem 0 0;
    background-color: rgba(172, 172, 172, 0.2);
    height: auto;
    overflow: hidden;
}

.mycorosel{
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 150rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
} 


.coroselButton{
    display: flex;
    justify-content: center;
    margin: 0 15rem 2rem 15rem;
    background-color: rgba(172, 172, 172, 0.2);
    padding-bottom: 1.5rem;
    border-radius: 0 0 2rem 2rem;
}


.coroselButton button{
    border: none;
    border-radius: 2rem;
    background-color: rgba(24, 125, 236, 0.13);
    color: black;
    padding: 0.7rem 3rem;
    font-weight: 700;
    margin: 1rem 0;
}

.coroselButton button:hover{
    background-color: rgba(172, 172, 172, 0.4);
    box-shadow: #2c9cf0 0 0 10px;
    transform: scale(1.1);
    border: 2px solid #2c9cf0;
    color: #2c9cf0;
}   

@media screen and (max-width: 4000px){
    .coroselContainer, .mycorosel{
        margin: 0 40rem;
    }
    .mycorosel{
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .coroselButton {
        margin: 0 40rem 2rem 40rem;
    }
    
}

@media screen and (max-width: 1920px){
    .coroselContainer, .mycorosel{
        margin: 0 15rem;
    }
    .mycorosel{
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .coroselButton {
        margin: 0 15rem 2rem 15rem;
    }
    
}

@media screen and (max-width: 768px){
    .coroselContainer, .mycorosel{
        margin: 0 5rem;
    }
    .mycorosel{
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .coroselButton {
        margin: 0 5rem 2rem 5rem;
    }
    
}




@media screen and (max-width: 425px){
    .coroselContainer, .mycorosel{
        margin: 0 1rem;
    }
    .coroselButton {
        margin: 0 auto;
    }
}