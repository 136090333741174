.row1, .row2, .row3 {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.scroll-content {
    display: flex;
    gap: 1rem;
    width: max-content;
}

.scroll-left .scroll-content {
    animation: scrollLeft 50s linear infinite;
}

.scroll-right .scroll-content {
    animation: scrollRight 50s linear infinite;
}

@keyframes scrollLeft {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
}

@keyframes scrollRight {
    0% { transform: translateX(-50%); }
    100% { transform: translateX(0); }
}

.pic img {
    width: 200px;  /* Fixed width for consistent scrolling */
    height: 150px; /* Fixed height for consistent scrolling */
    object-fit: cover;
}
