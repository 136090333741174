.banner-container {
  width: 100%;
  height: 60vh;
  max-height: 500px;
  min-height: 400px;
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  .heading-container {
    width: 50%;
    padding: 2rem;
    // background-color: var(--px-white);
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    font-family: var(--px-primary-font);
    .heading {
      font-weight: 700;
      font-size: 2.5rem;

      @include down-sm {
        font-size: 2rem;
      }
    }
    .subheading {
      font-size: 1.5rem;
      @include down-sm {
        font-size: 1rem;
      }
    }
    .stats {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      margin-top: 2rem;
      width: 100%;
      .stats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--px-purple);
        border-radius: 0.5rem;
        width: 30%;
        max-width: 150px;
        color: white;
        padding: 1rem;

        .stats-count {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }
        .stats-description {
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
      .stats-cta-container,
      .empty-container {
        .stats-cta-text {
          color: white;
        }
        .empty-container-text {
          color: var(--px-purple)
        }
        font-size: 0.8rem;
        font-weight: 700;
        background-color: var(--px-purple);
        border-radius: 0.5rem;
        padding: 20px;
      }
      .stats-cta-container {
        cursor: pointer;
      }
    }
    @include down-xsm {
      width: 100%;
    }
  }

  .carousel-container {
    width: 50%;
    height: 100%;
    padding: 1rem 0rem;
    .carousel {
      // position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    .slide {
      border-radius: 1rem 0rem 0rem 1rem;
      padding: 10px;
      //   width: 100%;
      height: 95%;
      width: auto;
      cursor: pointer;
      @media (min-width: 1400px) {
        border-radius: 1rem;
      }
    }
    .slide-hidden {
      display: none;
    }
    @include down-xsm {
      width: 100%;
      height: 300px;
      .slide {
        border-radius: 0;
      }
    }
    // .arrow {
    //     position: absolute;
    //     filter: drop-shadow(0px 0px 5px #555);
    //     width: 2rem;
    //     height: 2rem;
    //     color: white;
    // }
    // .arrow:hover {
    //     cursor: pointer;
    // }

    // .arrow-left {
    //     left: 1rem;
    // }

    // .arrow-right {
    //     right: 1rem;
    // }

    .indicators {
      display: flex;
      bottom: 1rem;
      z-index: 1000;
    }

    .indicator {
      background-color: white;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 100%;
      border: none;
      outline: none;
      box-shadow: 0px 0px 5px #555;
      margin: 0 0.2rem;
      cursor: pointer;
    }

    .indicator-inactive {
      background-color: grey;
    }
  }
  @include down-xsm {
    display: block;
    width: 100%;
    height: auto;
    max-height: 2000px;
  }
}

.carousel-image {
  //   width: 100%;
  height: 100%;
  width: auto;
  border: 1px solid #c1bcbc; /* Example: 2px solid black border */
  border-radius: 10px; /* Optional: Add border radius for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.595); /* Optional: Add shadow for better visibility */
}

// info banners
.info-banner,
.error-info-banner,
.warning-info-banner {
  margin-bottom: 20px;
  font-size: 1rem;
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
}
.info-banner {
  background-color: rgb(240, 239, 254);
  color: #4f4cee;
}
.warning-info-banner {
  background-color: rgb(255, 250, 230);
  color: #ff9f4f;
}
.error-info-banner {
  background-color: rgb(255, 240, 240);
  color: #ff4f4f;
}
